import React from 'react'
import PropTypes from 'prop-types'
import PrintListPDFCell from '/src/ui/core/grid/pdf_print/print_list_pdf_cell'
import { MdChat } from 'react-icons/md'

export default function PrintListPDFOtherColumns({ item, project, columns }) {
  if (!columns?.length) return null

  return (
    <div className='print-list-pdf__other-columns'>
      {columns.map((column) => {
        const flexibleComments = item.flexible_comments ?? {}
        const comment = flexibleComments[column.description]

        return (
          <div className='print-list-pdf__other-column' key={column.description}>
            <div className='print-list-pdf__column-title'>
              {column.title}
            </div>
            <div className='print-list-pdf__column-value'>
              <PrintListPDFCell item={item} column={column} project={project} />
            </div>
            {comment && (
              <div className='print-list-pdf__column-comment'>
                <MdChat size={15} style={{ color: '#607d8b' }} />
                <span>{comment}</span>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

PrintListPDFOtherColumns.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.arrayOf(PropTypes.shape({
    column_type: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string
    })
  })),
  project: PropTypes.oneOfType([PropTypes.object]).isRequired
}

PrintListPDFOtherColumns.defaultProps = {
  item: undefined,
  columns: undefined
}

import React from 'react'
import Layout from '/src/ui/core/layouts/layout'

export default function Authorization({ cookies }) {
  return (
    <Layout>
      <p>I might (or not) authorize you actions...</p>
    </Layout>
  )
}

import React from 'react'
import Layout from '/src/ui/core/layouts/layout'

export default function SettingsPage() {
  return (
    <Layout key="tickets" showProjectSwitcher={false}>
      <p>I will have tickets later on the road. I promise.</p>
    </Layout>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import RequiredMark from '/src/ui/core/alerts/required_mark'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import InformationTooltip from '/src/ui/core/tooltips/information_tooltip'

export default function InputTitle({ htmlFor, title, required, information, children, className }) {
  if (!title) return null

  return (
    <label className={`column-label ${className}`} htmlFor={htmlFor}>
      <div className="column-label-first-row">
        <CustomTooltip position="auto">
          <div title={title} className={`column-label-title ${className}`}>
            <RequiredMark required={required} />
            {title}
          </div>
        </CustomTooltip>
        {information && <InformationTooltip title={information} />}
      </div>
      {children}
    </label>
  )
}

InputTitle.propTypes = {
  htmlFor: PropTypes.string,
  title: PropTypes.string,
  information: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.element,
  className: PropTypes.string
}

InputTitle.defaultProps = {
  htmlFor: '',
  title: '',
  information: '',
  required: false,
  children: undefined,
  className: ''
}

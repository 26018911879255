import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { MdCheck, MdClose, MdFilterAlt } from 'react-icons/md'
import { isEmpty } from 'lodash'
import I18n from '/src/utils/translations'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import PopupSearch from '/src/ui/core/popups/popup_search'
import '/src/static/css/core/blocks/timeline_filter.css'

export default function InspectionTimelineTemplateFilter({ indexifiedTemplates, filterTemplate, setFilterTemplate }) {
  const [filterSearch, setFilterSearch] = useState('')
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const filterPopupButtonRef = useRef(null)

  const resetFilterPopup = () => {
    setIsFilterOpen(false)
    setFilterSearch('')
  }

  const handleSelectTemplate = (templateTitle) => {
    if (filterTemplate.includes(templateTitle)) {
      const newFilter = filterTemplate.filter((item) => item !== templateTitle)
      setFilterTemplate(newFilter)
    } else {
      const newFilter = [...filterTemplate, templateTitle]
      setFilterTemplate(newFilter)
    }
    resetFilterPopup()
  }

  const buildTemplateFilterOptions = () => {
    if (!indexifiedTemplates) return <div />

    return (
      <div className="timeline-filter__popup-body" data-testid="filter-popup">
        <PopupSearch onChange={(e) => setFilterSearch(e.target.value.toLowerCase())} />
        {Object.values(indexifiedTemplates).map((template) => {
          if (filterSearch && !template.title.toLowerCase().includes(filterSearch)) return null

          const isActive = filterTemplate.includes(template.title)
          const additionalClassNames = isActive ? 'timeline-filter__option--active' : ''

          return (
            <div
              key={template.title}
              onClick={() => handleSelectTemplate(template.title)}
              className={`timeline-filter__option ${additionalClassNames}`}
            >
              {template.title}
              <span className="timeline-filter__option-check">{isActive && <MdCheck />}</span>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="timeline-filter">
      {!isEmpty(filterTemplate) && (
        <div className="timeline-filter__filtered-label">
          <span className="timeline-filter__filtered-text">
            {`${I18n.t('side_panel.tabs.template_filter')}: ${filterTemplate.join(', ')}`}
          </span>
          <MdClose onClick={() => setFilterTemplate([])} data-testid="clear-timeline-filters" />
        </div>
      )}
      <PopupAnchored
        body={buildTemplateFilterOptions()}
        popupButtonRef={filterPopupButtonRef}
        anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
        popupAlign={{ horizontal: 'right', vertical: 'top' }}
        forceOpen={isFilterOpen}
        setForceOpen={() => isFilterOpen}
        onClickOutside={() => resetFilterPopup()}
      >
        <span
          className="timeline-filter__button"
          ref={filterPopupButtonRef}
          onClick={() => setIsFilterOpen((prev) => !prev)}
          data-testid="timeline-filter-button"
        >
          <MdFilterAlt />
        </span>
      </PopupAnchored>
    </div>
  )
}

InspectionTimelineTemplateFilter.propTypes = {
  indexifiedTemplates: PropTypes.oneOfType([PropTypes.object]),
  filterTemplate: PropTypes.arrayOf(PropTypes.string),
  setFilterTemplate: PropTypes.func.isRequired
}

InspectionTimelineTemplateFilter.defaultProps = {
  indexifiedTemplates: undefined,
  filterTemplate: []
}

import useFetch from '/src/hooks/api/fetch'

export function categorizeColumnsByType(gridColumns) {
  return gridColumns.reduce((columnsCategorized, column) => {
    if (column.column_type?.description === 'picture') {
      columnsCategorized.pictureColumns.push(column)
    } else if (column.column_type?.description === 'multiline_text') {
      columnsCategorized.multilineTextColumns.push(column)
    } else if (column.column_type?.description === 'attachment') {
      columnsCategorized.attachmentColumns.push(column)
    } else {
      columnsCategorized.otherColumns.push(column)
    }
    return columnsCategorized
  }, { pictureColumns: [], multilineTextColumns: [], attachmentColumns: [], otherColumns: [] })
}

export function usePDFColumnsAndTemplate() {
  const { fetch } = useFetch()

  const fetchColumnsAndTemplate = async (templateId, modelName) => {
    const columnsParams = { query: { where: { eav_template_id: templateId } } }
    const templateParams = { query: { where: { id: templateId } } }

    if (modelName === 'Work Orders') {
      const { data: columnsData } = await fetch('eav_columns', columnsParams, { useParse: true })
      return [columnsData]
    }

    const columnsFetch = fetch('eav_columns', columnsParams, { useParse: true })
    const templateFetch = fetch('eav_templates', templateParams, { useParse: true })

    const [{ data: columnsData }, { data: templateData }] = await Promise.all([columnsFetch, templateFetch])

    return [columnsData, templateData[0]?.title]
  }

  return { fetchColumnsAndTemplate }
}

export function categorizePicturesByColumns(item, columns) {
  return columns.reduce((picturesCategorized, { description }) => {
    const itemPicturesUuids = item[description]

    if (!itemPicturesUuids?.length) {
      return picturesCategorized
    }

    const columnPicturesArray = item.pictures.filter((picture) => itemPicturesUuids.includes(picture.uuid))

    if (!columnPicturesArray.length) {
      return picturesCategorized
    }

    return {
      ...picturesCategorized,
      [description]: columnPicturesArray
    }
  }, {})
}

import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import Layout from '/src/ui/core/layouts/layout'
import I18n from '/src/utils/translations'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useModelController from '/src/hooks/model_controller'
import SubRequestsGrid from '/src/ui/domain/sub_requests/sub_requests_grid'

export default function SubRequest({ match }) {
  const history = useHistory()

  const { dataItem } = useModelController({
    modelType: 'requests',
    dataItemId: match.params.id,
    onBack: () => history.goBack()
  })

  const [eav] = useQueryParam('eav_template_id', NumberParam)
  const [label] = useQueryParam('label', StringParam)

  return (
    <Layout showProjectSwitcher={false}>
      {dataItem ? <SubRequestsGrid request={dataItem} scopeTemplateId={eav} label={label} /> : <ThreeDotsLoader />}
    </Layout>
  )
}

SubRequest.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

SubRequest.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: undefined
    })
  })
}

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import useRequestsPinned from '/src/hooks/requests_pinned'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import PopupScopeLabel from '/src/ui/domain/scopes/popup_scope_label'
import { isEmpty } from '/src/utils/object'

export default function LabelIcon() {
  const { selectedRequests } = useRequestsPinned()
  const [selectedItems, setSelectedItems] = useState([])
  const url = useParams()

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ payload }) => {
      setSelectedItems(payload)
    },
    [setSelectedItems]
  )

  const isScopePage = () => url.resource === 'scopes' && !url.collection
  const isVisible = () => !isEmpty(selectedRequests) && isScopePage() && selectedItems.length

  return isVisible() ? <PopupScopeLabel selectedItems={selectedItems} /> : null
}

import I18n from '/src/utils/translations'
import { isPresent } from '/src/utils/refinements'
import { isEmpty } from '/src/utils/object'
import { toLower } from 'lodash'

const translationPath = 'inspections.inspected_modules'

const inspectedFormatter = (inspected, type, services) => {
  if (!isPresent(type)) return ''

  const typeTranslations = {
    Progress: 'progress_services',
    Scope: 'estimate_services'
  }

  if (services && !isEmpty(services) && type) {
    const inspectedType = I18n.t([translationPath, toLower(type)].join('.'))
    const templateTitle = inspected.eav_template.title
    const translatedService = I18n.t([translationPath, typeTranslations[type]].join('.'))

    return `#${inspected.number} - ${inspectedType} - ${templateTitle} - ${translatedService}`
  }

  const inspectedType = I18n.t([translationPath, type.toLowerCase()].join('.'))

  if (isPresent(inspected.number) && isPresent(inspected.eav_template)) {
    return `#${inspected.number} - ${inspectedType} - ${inspected.eav_template.title}`
  }

  if (type === 'Scaffolding' && isPresent(inspected.tagNumber) && isPresent(inspected.description)) {
    return `TAG #${inspected.tagNumber} - ${inspected.description}`
  }

  return inspectedType
}

export default inspectedFormatter

/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import I18n from '/src/utils/translations'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import FormSectionsSummary from '/src/ui/core/forms/form_sections_summary'
import '/src/static/css/core/layouts/page_form.css'

const formTypes = {
  new: 'form.create_entity',
  edit: 'form.update_entity',
  duplicate: 'form.duplicate_entity'
}

export default function FormWrapper({
  model,
  label,
  badge,
  children,
  classes,
  type,
  title,
  isModal,
  showSidePanel,
  sidePanelSections,
  steps,
  activeStep,
  isInBatch
}) {
  const modelName = isInBatch ? 'name' : 'singularName'
  const formTitle = title || I18n.t(formTypes[type], { entity: model[modelName] })
  const [showConfirmation, renderConfirmation] = useConfirmationModal(true)
  const [visibleSections, setVisibleSections] = useState(sidePanelSections)

  const completeCancel = () => {
    dispatch(BusEvents.FORM_CANCEL_BUTTON_CLICKED)
  }

  const staticParams = {
    title: I18n.t('discard_modal.title'),
    description: I18n.t('discard_modal.description'),
    actionButtonText: I18n.t('discard_modal.ok'),
    cancelButtonText: I18n.t('discard_modal.cancel'),
    buttonColor: '#B33939'
  }

  const getConfirmationParams = () => ({
    ...staticParams,
    onSuccess: () => completeCancel
  })

  const canShowSidePanel = () => {
    return sidePanelSections && showSidePanel.includes(type)
  }

  const formWrapperClass = () => {
    const className = [classes]
    if (canShowSidePanel()) className.push('full')
    if (isModal) className.push('form-wrapper-modal')
    return className.join(' ')
  }

  const updateVisibleSections = (section) => {
    let visibles = visibleSections
    if (!visibles || visibles.length === 0) visibles = sidePanelSections

    if (!visibles) return
    const index = visibles.findIndex((sec) => sec.id === section.id)
    if (index === -1) return
    const newVisibleSections = visibles.map((x) => x)
    newVisibleSections[index].hide = !section.shouldDisplay
    setVisibleSections(newVisibleSections)
  }

  useEffect(() => {
    if (!sidePanelSections || sidePanelSections.length == 0) return

    const newVisibleSections = sidePanelSections.map((section) => {
      if (section.id === 'general') return section
      return { ...section, hide: true }
    })

    setVisibleSections(newVisibleSections)
  }, [sidePanelSections])

  useBus(BusEvents.FORM_DISCARD_CHANGES_CONFIRM, () => showConfirmation(getConfirmationParams()), [
    showConfirmation,
    getConfirmationParams
  ])

  useBus(BusEvents.FORM_SECTION_DISPLAY_UPDATE, ({ payload }) => updateVisibleSections(payload), [
    updateVisibleSections
  ])

  return (
    <div className={`form-wrapper ${formWrapperClass()}`}>
      {renderConfirmation()}
      <div className="form-wrapper-header">
        <div className="form-title-badge-wrapper">
          <div className="form-wrapper-title" data-testid="form-title">
            {formTitle}
          </div>
          {label && <div className="form-wrapper-label">{label}</div>}
          <span id="badge" className="badge">
            {badge}
          </span>
        </div>
        {steps && (
          <div className="steps-summary">
            {steps.map((step, index) => (
              <div key={index} className={classNames('step-label', { 'step-label-active': index === activeStep })}>
                {step}
              </div>
            ))}
          </div>
        )}
        {visibleSections && canShowSidePanel() && <FormSectionsSummary sections={visibleSections} />}
      </div>
      <div className="form-wrapper-body">
        <div className="form">{children}</div>
      </div>
    </div>
  )
}

FormWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  label: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  badge: PropTypes.element,
  classes: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  showSidePanel: PropTypes.arrayOf(PropTypes.string),
  sidePanelSections: PropTypes.arrayOf(PropTypes.object),
  isModal: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number,
  isInBatch: PropTypes.bool
}

FormWrapper.defaultProps = {
  label: undefined,
  badge: undefined,
  classes: '',
  type: 'new',
  title: undefined,
  showSidePanel: ['new', 'edit', 'duplicate'],
  sidePanelSections: undefined,
  isModal: false,
  steps: undefined,
  activeStep: 0,
  isInBatch: false
}

import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useQueryParam, NumberParam } from 'use-query-params'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import CellFactory from '/src/ui/core/grid/column_cell_factory/cell_factory'
import GridEmptyState from '/src/ui/core/grid/grid_empty_state'
import useRequestFilterByDiscipline from '/src/hooks/request_filter_by_discipline'
import I18n from '/src/utils/translations.js'
import '/src/static/css/templatable_grid.css'

export default function TemplatableGrid({
  model,
  filter,
  icons,
  contextMenuItems,
  onRowClick,
  gridTitle,
  labels,
  selectedItem,
  onTemplateChange,
  selectFiltering,
  selecting,
  onFilterUpdate,
  page,
  onDataSource,
  onGridColumns,
  onRowRender,
  clearAllFiltersCallback
}) {
  const [templateId] = useQueryParam('eav_template_id', NumberParam)
  const sort = [{ field: 'id', dir: 'desc' }]

  const filterByDiscipline = useRequestFilterByDiscipline()

  const filters = useMemo(() => {
    if (!templateId) return null
    return [...(filter || []), { type: 'where', column: 'eav_template_id', value: templateId }]
  }, [filter, templateId])

  useEffect(() => {
    if (templateId && onTemplateChange) onTemplateChange(templateId)
  }, [templateId, onTemplateChange])

  if (filterByDiscipline && !templateId)
    return (
      <div className="entity-grid-wrapper">
        <GridEmptyState modelName={`${model.name} templates`} message={I18n.t('empty_state.no_discipline_template')} />
      </div>
    )

  return filters ? (
    <div className="templatable-grid">
      <SimpleGrid
        key={templateId}
        labels={labels}
        icons={icons}
        model={model}
        sort={sort}
        filter={filters}
        clearAllFiltersCallback={clearAllFiltersCallback}
        contextMenuItems={contextMenuItems}
        gridTitle={gridTitle}
        loadFlexColumns
        templateId={templateId}
        columnCellFactory={<CellFactory type={model.paramName} />}
        onRowClick={onRowClick}
        onRowRender={onRowRender}
        selectedItem={selectedItem}
        onFilterUpdate={onFilterUpdate}
        onDataSource={onDataSource}
        onGridColumns={onGridColumns}
        selectFiltering={selectFiltering}
        selecting={selecting}
        page={page}
      />
    </div>
  ) : null
}

TemplatableGrid.propTypes = {
  contextMenuItems: PropTypes.arrayOf(PropTypes.object),
  model: PropTypes.shape({
    name: PropTypes.string.isRequired,
    paramName: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired
  }).isRequired,
  gridTitle: PropTypes.string,
  filter: PropTypes.oneOfType([PropTypes.array]),
  labels: PropTypes.oneOfType([PropTypes.array]),
  icons: PropTypes.oneOfType([PropTypes.array]),
  selectedItem: PropTypes.object,
  selectFiltering: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowRender: PropTypes.func,
  onTemplateChange: PropTypes.func,
  selecting: PropTypes.bool,
  onFilterUpdate: PropTypes.func,
  onGridColumns: PropTypes.func,
  onDataSource: PropTypes.func,
  clearAllFiltersCallback: PropTypes.func
}

TemplatableGrid.defaultProps = {
  contextMenuItems: null,
  templates: [],
  labels: [],
  gridTitle: undefined,
  filter: [],
  icons: ['more'],
  selectedItem: undefined,
  onRowClick: undefined,
  onRowRender: undefined,
  onTemplateChange: undefined,
  selectFiltering: true,
  selecting: false,
  onFilterUpdate: undefined,
  onGridColumns: undefined,
  onDataSource: undefined,
  clearAllFiltersCallback: () => {}
}

import React from 'react'
import Block from '/src/ui/core/grid/side_panel/block'
import ImageGalleryBlock from '/src/ui/core/blocks/image_gallery_block'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import RequestModel from '/src/models/request'
import ScopeModel from '/src/models/scope'
import EstimateServiceModel from '/src/models/estimate_service'
import ProgressModel from '/src/models/progress'
import ProgressServiceModel from '/src/models/progress_service'
import InspectionModel from '/src/models/inspection'
import ScaffoldingModel from '/src/models/scaffolding'
import RelatedInspectionsBlock from '/src/ui/domain/inspections/related_inspections_timeline'

/**
 * This function prepares the info tab of the side panel.
 * It displays what we usually had in the side panel before.
 * @param {Object[]} blocks - An array of objects containing the blocks to render.
 * @param {String} [customTabName] - A key to be used in the yml files under
 * 'side_panel.tabs'. Defaults to 'info'.
 * @returns {Object} An object containing the name of the tab ('info') and its body.
 */
export function getInfoTab(blocks, customTabName) {
  const blocksToRender =
    !blocks || blocks.length === 0
      ? []
      : blocks
          .filter(({ visible }) => visible !== false)
          .map((block) => <Block block={block} key={block.key || block.title} />)

  const infoTab = {
    name: customTabName || 'info',
    body: blocksToRender
  }

  return infoTab
}

export function getModel(name) {
  switch (name) {
    case 'request':
      return new RequestModel()
    case 'scope':
      return new ScopeModel()
    case 'estimate_service':
      return new EstimateServiceModel()
    case 'progress':
      return new ProgressModel()
    case 'progress_service':
      return new ProgressServiceModel()
    case 'inspection':
      return new InspectionModel()
    case 'scaffolding':
      return new ScaffoldingModel()
    default:
      return undefined
  }
}

function getGalleryColumns({ moduleName, sections, dataItem }) {
  if (!sections || !sections.length || !dataItem) return []

  const allPictures = dataItem.pictures || []
  const columns = []

  sections.forEach((section) => {
    const sectionColumns = section.eav_columns || section.columns
    const pictureColumns =
      sectionColumns?.filter((field) => {
        const columnType = field.type || field.column_type.description
        return ['picture', 'fixed_picture'].includes(columnType)
      }) || []

    if (pictureColumns.length === 0) return

    pictureColumns.forEach((column) => {
      const columnDescription = column.description || ''
      let filteredPictures
      if (!column?.fixedPicture) {
        const imageUuids = dataItem[columnDescription] || []
        filteredPictures = allPictures.filter((picture) => imageUuids.includes(picture.uuid))
      } else {
        filteredPictures = dataItem.pictures
      }
      const model = getModel(moduleName)
      columns.push({
        pictures: filteredPictures,
        name: column.title,
        module: model?.singularName,
        id: column.id,
        description: column.description,
        type: column.type,
        model: getModel(moduleName),
        dataItem: {
          ...dataItem,
          route: model?.route
        },
        fixedPicture: column?.fixedPicture
      })
    })
  })

  return columns
}

/**
 * This function receives module objects and creates gallery columns for each one.
 * @param {Object[]} modules - An array of objects containing moduleName, sections and dataItem.
 * @param {'request'|'scope'|'estimate_service'|'progress'|'progress_service'|'inspection'}
 * modules[].moduleName - Check accepted names above.
 * @param {Object[]} modules[].sections - An array of eav_sections.
 * @param {Object} modules[].dataItem - The dataItem of the selected item on the grid.
 * @param {boolean} loading - If true, the gallery displays only a loading animation.
 * @returns {Object} An object containing the name of the tab ('gallery') and its body.
 * @returns {'gallery'} Object.name - The name of the tab.
 * @returns {ReactElement} Object.body - Either <ThreeDotsLoader /> or <ImageGalleryBlock />.
 */
export function getGalleryTab({ modules, loading, showButtons = true, defaultExpand = null }) {
  if (loading) return { name: 'gallery', body: <ThreeDotsLoader /> }

  const columns = []
  modules.forEach((eachModule) => {
    const column = getGalleryColumns(eachModule)
    columns.push(...column)
  })

  return {
    name: 'gallery',
    body: <ImageGalleryBlock columns={columns} showButtons={showButtons} defaultExpand={defaultExpand} />
  }
}

/**
 * This function creates an Inspections Tab to display related inspections from a dataItem.
 * @param {Object} dataItem - The original item, from which we get the related inspections.
 * @param {Array} sections - The sections of the item template.
 * @param {string} inspectedType - The string to use as "inspected_type" in the API request.
 * @returns A "Tab" object, containing a name (string) and a body (component).
 */
export function getInspectionsTab(dataItem, inspectedType, showButtons = true) {
  const inspectionDataItem = {
    ...dataItem,
    inspected_id: inspectedType === 'Request' ? null : dataItem.id,
    inspected_type: inspectedType,
    eav_template_id: null
  }

  return {
    name: 'inspections',
    body: <RelatedInspectionsBlock dataItem={inspectionDataItem} showButtons={showButtons} isInspectionsTab />
  }
}

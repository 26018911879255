/* eslint-disable max-lines-per-function */
import { useState, useEffect } from 'react'
import I18n from '/src/utils/translations'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifyError } from '/src/ui/core/dialogs/notifications'

export default function useModelController({ modelType, dataItemId, onBack, onFinish, onFinishAndAnother }) {
  const [dataItem, setDataItem] = useState(null)
  const { loading, status, responseData, fetchAPI } = useFetchAPI(modelType)

  useBus(BusEvents.FORM_CANCEL_BUTTON_CLICKED, () => onBack(), [onBack])

  useBus(BusEvents.FORM_BACK_BUTTON_CLICKED, () => onBack(), [onBack])

  useBus(
    BusEvents.FORM_FINISHED,
    ({ payload }) => {
      if (payload && payload.newClicked && onFinishAndAnother) onFinishAndAnother()
      else onFinish()
    },
    [onFinish]
  )

  useEffect(() => {
    if (!dataItemId) return
    const queryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: parseInt(dataItemId, 10) } }
    }
    fetchAPI(queryParams)
  }, [])

  useEffect(() => {
    if (status === 'ERROR') {
      notifyError({
        title: I18n.t('notification.error'),
        body: I18n.t(`${modelType}.errors.not_found`, { id: dataItemId })
      })
    }

    if (status !== 'SUCCESS') return

    if (responseData.data.length > 0) {
      setDataItem(responseData.data[0])
      return
    }

    notifyError({
      title: I18n.t('notification.access_denied'),
      body: I18n.t('notification.correct_subproject')
    })
  }, [status, responseData])

  return { dataItem, loading }
}

/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useStore } from 'react-context-hook'
import I18n from '/src/utils/translations'
import { IsEqualOperator } from '/src/models/concerns/filter_operators'
import CircleStatusFilterIcon from '/src/ui/core/icons/circle_status_filter_icon'

const STATUS_BLACKLIST = ['in_performance', 'on_hold']

const extra = { id: 8, active: true, description: 'Extra', filtered: false, i18n_id: 'extra' }
const pendingInspection = {
  id: 9,
  active: true,
  description: 'Pending Inspection',
  filtered: false,
  i18n_id: 'inspected_flag'
}

const statusDescription = (status) => {
  const statusTitle = I18n.t(`progress_services.statuses.${status.i18n_id}`)
  if (statusTitle.length <= 20) return statusTitle
  return `${statusTitle.substring(0, 20)}...`
}

const getFilteredStatusesNames = (newStatuses) => {
  const statusesNames = newStatuses
    .filter((x) => x.filtered)
    .map((x) => I18n.t(`progress_services.statuses.${x.i18n_id}`))
  return statusesNames
}

const removeSelectedFilter = (filter, { column, operator }) => {
  return filter.filter((x) => x.column !== column || x.operator !== operator)
}

const addFilter = (filterObject, source) => {
  const newFilter = source.map((x) => x)
  newFilter.push(filterObject)
  return newFilter
}

export default function useProgressServicesStatusFilter(filter, onFilter, onSelectedOptionChange) {
  const [popupOpened, setPopupOpened] = useState(false)
  const [statuses, setStatuses] = useState([])

  const [progressServiceStatuses] = useStore('progress_service_statuses')

  const allowedStatuses = statuses.filter((s) => s.id && !STATUS_BLACKLIST.includes(s.i18n_id))

  useEffect(() => {
    if (!progressServiceStatuses || progressServiceStatuses.length === 0) return

    const statusList = Object.values(progressServiceStatuses).map((s) => {
      return { ...s, filtered: false }
    })
    setStatuses([...statusList, extra, pendingInspection])
  }, [progressServiceStatuses])

  // eslint-disable-next-line max-params, no-shadow
  function handleSpecialFilters(filterIds, specialFilter, specialFilterObject, filter, selectedStatus, newFilter) {
    const isFilteringSpecial = filterIds.includes(specialFilter.id)
    filterIds = filterIds.filter((item) => item !== specialFilter.id)
    if (selectedStatus.i18n_id === specialFilter.i18n_id) {
      newFilter = removeSelectedFilter(newFilter, specialFilterObject)
    }
    if (isFilteringSpecial) {
      newFilter = addFilter(specialFilterObject, newFilter)
    }
    return { filterIds, newFilter }
  }

  const changeFilter = useCallback(
    (selectedStatus) => {
      if (!popupOpened) return

      setPopupOpened(false)
      const newStatuses = statuses.map((x) => x)
      const { filtered } = selectedStatus
      newStatuses.filter((s) => selectedStatus.id === s.id).forEach((s) => (s.filtered = !filtered))
      let filterIds = newStatuses.filter((x) => x.filtered).map((s) => s.id)
      if (!filterIds) return

      const filterObject = {
        type: 'where',
        column: 'progress_service_status_id',
        operator: IsEqualOperator,
        value: filterIds
      }

      const extraFilterObject = {
        type: 'where',
        column: 'estimate_service_id',
        operator: IsEqualOperator,
        value: null
      }

      const pendingInspectionFilterObject = {
        type: 'where',
        column: 'inspected_flag',
        operator: IsEqualOperator,
        value: 'inspected_flag'
      }

      // Remove selected filters from grid filters
      let newFilter = removeSelectedFilter(filter, filterObject)

      // Handle extra and pending inspection filters
      const resultForExtra = handleSpecialFilters(
        filterIds,
        extra,
        extraFilterObject,
        filter,
        selectedStatus,
        newFilter
      )

      filterIds = resultForExtra.filterIds
      newFilter = resultForExtra.newFilter

      const resultForPendingInspection = handleSpecialFilters(
        filterIds,
        pendingInspection,
        pendingInspectionFilterObject,
        filter,
        selectedStatus,
        newFilter
      )
      filterIds = resultForPendingInspection.filterIds
      newFilter = resultForPendingInspection.newFilter

      // Add remaining progress service status filters
      if (filterIds.length > 0) {
        newFilter = addFilter(filterObject, newFilter)
      }

      onFilter(newFilter)
      setStatuses(newStatuses)
      onSelectedOptionChange(getFilteredStatusesNames(newStatuses))
    },
    [popupOpened, statuses, filter, onFilter, onSelectedOptionChange]
  )

  const resetFilters = useCallback(() => {
    setPopupOpened(false)
    const newStatuses = statuses.map((s) => {
      return { ...s, filtered: false }
    })
    setStatuses(newStatuses)

    onSelectedOptionChange(getFilteredStatusesNames(newStatuses))
  }, [statuses, onSelectedOptionChange, setStatuses])

  const clearFilters = useCallback(() => {
    resetFilters()
    const newFilters = filter.filter(
      (x) =>
        x.column !== 'progress_service_status_id' && x.column !== 'estimate_service_id' && x.column !== 'inspected_flag'
    )
    onFilter(newFilters)
  }, [resetFilters, filter, onFilter])

  const hasStatusFilter = useMemo(() => {
    return filter.some(
      (f) =>
        f.column === 'progress_service_status_id' || f.column === 'estimate_service_id' || f.column === 'inspected_flag'
    )
  }, [filter])

  const statusFilterButton = useMemo(
    () => (
      <CircleStatusFilterIcon
        statuses={allowedStatuses}
        statusDescription={statusDescription}
        isStatusSelected={(status) => status.filtered}
        onChangeFilter={changeFilter}
        onClearFilters={clearFilters}
        popupOpened={popupOpened}
        setPopupOpened={setPopupOpened}
      />
    ),
    [allowedStatuses, changeFilter, clearFilters, popupOpened]
  )

  return {
    statusFilterButton,
    hasStatusFilter,
    resetStatusFilters: resetFilters,
    clearStatusFilters: clearFilters
  }
}

import React from 'react'
import PropTypes from 'prop-types'

export default function RadioButtonOption({ inputProps, option }) {
  const { id, className, inputValue, onChange, readOnly, preventNull } = inputProps
  let label = option
  let value = option

  if (typeof option === 'object') ({ label, value } = option)

  const onClick = (e) => {
    if (inputValue === value && !preventNull) e.target.value = null
    onChange(e)
  }

  return (
    <div className="option">
      <input
        type="radio"
        id={id}
        className={className}
        name={id}
        value={value}
        checked={inputValue === value}
        onClick={onClick}
        onChange={() => {}}
        disabled={readOnly}
      />
      <label title={label} htmlFor={id} className={className}>
        {label}
      </label>
    </div>
  )
}

RadioButtonOption.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    inputValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    preventNull: PropTypes.bool
  }).isRequired,
  option: PropTypes.oneOfType([PropTypes.number, PropTypes.bool, PropTypes.string, PropTypes.object]).isRequired
}

import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import { getFormatLocaleDate, normalizeDateToString, normalizeStringToLocalDate } from '/src/utils/project_formatter'
import useInputError from '/src/ui/core/inputs/input_error'
import { isBlank, isPresent } from '/src/utils/boolean_refinements'
import '/src/static/css/input_date.css'

const convertValueToDate = (value) => {
  if (value === 'current_date') return new Date()
  else return isPresent(value) ? normalizeStringToLocalDate(value) : null
}

// eslint-disable-next-line max-lines-per-function
export default function InputDate({ inputProps }) {
  const { id, className, title, required, value, readOnly, onChange, min, max } = inputProps
  const [project] = useStore('project')
  const [format, setFormat] = useState('dMy')
  const [date, setDate] = useState(convertValueToDate(value))
  const minDate = isPresent(min) ? normalizeStringToLocalDate(min) : min
  const maxDate = isPresent(max) ? normalizeStringToLocalDate(max) : max
  const inputValue = normalizeDateToString(date)
  const error = useInputError({ inputValue, title, type: 'date', required, min, max })

  const performParentChange = () => {
    if (!onChange) return
    if (date === null) onChange(id, null)
    else if (date === undefined && value) onChange(id, value)
    else if (date !== undefined) onChange(id, normalizeDateToString(date))
  }

  const onBlur = () => {
    if (!date) setFormat('ddMMyyyy')
    performParentChange()
  }

  const onFocus = () => {
    if (!date) setFormat(getFormatLocaleDate(project))
  }

  const inputClass = () => {
    const classes = ['form-input-date']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  const handleChange = (e) => {
    setDate(e.target.value)
  }

  useEffect(() => {
    if (date) setFormat(getFormatLocaleDate(project))
  }, [project, date])

  useEffect(() => {
    if ((isBlank(date) && isBlank(value)) || date === value || normalizeDateToString(date) === value) return
    let newDate = convertValueToDate(value)
    setDate(newDate)
    onChange(id, normalizeDateToString(newDate))
  }, [id, value])

  return (
    <React.Fragment>
      <DatePicker
        id={id}
        className={inputClass()}
        defaultValue={date}
        value={date}
        format={format}
        formatPlaceholder={{ day: ' ', month: ' ', year: ' ' }}
        onChange={handleChange}
        disabled={readOnly}
        onBlur={onBlur}
        onFocus={onFocus}
        min={minDate}
        max={maxDate}
        weekNumber
      />
      <div className="error-label">{error}</div>
    </React.Fragment>
  )
}

InputDate.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    title: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    readOnly: PropTypes.bool,
    min: PropTypes.string,
    max: PropTypes.string
  }).isRequired
}

import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { isBlank } from '/src/utils/boolean_refinements'

export default function DeltaQuantityColumn({ data }) {
  const [globalProject] = useStore('project')
  return deltaQuantity(data, globalProject)
}

DeltaQuantityColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object])
}

DeltaQuantityColumn.defaultProps = {
  data: {}
}

export const deltaQuantity = (data, project) => {
  if (isBlank(data) || isBlank(data.quantity) || isBlank(data.progress_summary)) return '---'
  if (isBlank(data.progress_summary.actual_quantity)) return '---'

  return formatNumberWithPrecision(data.progress_summary.actual_quantity - data.quantity, project)
}

import { format } from 'date-fns'
import { normalizeDateToString } from '/src/utils/project_formatter'
import { includeDateColumns } from '/src/models/concerns/datetime_columns'
import { foreignColumns } from '/src/models/concerns/grid'
import { RECYCLING_STATUS, DISCIPLINE_CHECK_PENDING_STATUS } from '/src/utils/constants/request'
import { titleLimiter } from '/src/utils/string'
import { isPresent } from '/src/utils/boolean_refinements'
import DefaultI18n from '/src/utils/translations'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'

export const hasRequestSummary = (dataItem) => {
  return dataItem?.team_target_hours !== undefined && isPresent(dataItem?.actual_progress)
}

export const isInScopingOrConfirmed = (request = {}, statuses = {}) => {
  const { request_status_id } = request
  const allowedStatuses = ['confirmed', 'in_scoping']
  return allowedStatuses.includes(statuses[request_status_id])
}

export const isRecyclingRequest = (request = {}, statuses = {}) => {
  return isInScopingOrConfirmed(request, statuses) && request.recycling_status === RECYCLING_STATUS
}

export const isRecyclableRequest = (request = {}, statuses = {}) => {
  return isInScopingOrConfirmed(request, statuses) && request.recycling_status !== RECYCLING_STATUS
}

export const isDisciplineCheckPending = (request = {}, statuses = {}) => {
  return (
    isInScopingOrConfirmed(request, statuses) && request.discipline_check_status === DISCIPLINE_CHECK_PENDING_STATUS
  )
}

export const isReadyForDisciplineCheck = (request = {}, statuses = {}) => {
  return (
    isInScopingOrConfirmed(request, statuses) && request.discipline_check_status !== DISCIPLINE_CHECK_PENDING_STATUS
  )
}

export const workPackageFilename = (request, disciplines) => {
  const { reason, comments, discipline_id: disciplineId } = request || {}
  const { description: disciplineDescription } = disciplines[disciplineId] || {}

  const requestComments = comments || I18n.t('requests.no_comment')
  const revision = format(new Date(), 'yyyy-MM-dd_HH-mm')

  const maxStringLength = 50
  const reasonStr = titleLimiter(reason, maxStringLength)
  const commentsStr = titleLimiter(requestComments, maxStringLength)

  return DefaultI18n.t('requests.work_package_file_name', {
    reason: reasonStr,
    disciplineDescription,
    comments: commentsStr,
    revision
  })
}

export default class RequestModel {
  constructor() {
    this.paramName = RequestModel.paramName
    this.singularName = RequestModel.singularName
    this.route = RequestModel.route
  }

  static paramName = 'request'

  static singularName = I18n.t('requests.description')

  static route = 'requests'

  name = I18n.t('requests.title')

  flexible = true

  footer = true

  showTotals = false

  skip_scoping = false

  estimates_authorization = false

  cannotEditStatuses = ['canceled', 'done', 'deleting']

  gridColumnOrderOffset = 3

  initialColumns = [
    {
      id: 18,
      description: 'sub',
      title: ' ',
      hideOnSummary: true,
      hideOnSidePanel: true,
      hideOnForm: true,
      filterable: false,
      sortable: false,
      width: 50
    },
    {
      id: 1,
      description: 'id',
      title: I18n.t('requests.id'),
      hideOnSummary: true,
      hideOnSidePanel: true,
      hideOnForm: true,
      editable: false,
      width: 200,
      type: 'integer'
    },
    {
      id: 2,
      description: 'request_status',
      title: I18n.t('requests.status'),
      hideOnSummary: true,
      hideOnSidePanel: true,
      hideOnForm: true,
      sortable: false,
      filterable: true,
      editable: false,
      width: 200,
      type: 'text'
    },
    {
      id: 19,
      description: 'progress',
      title: I18n.t('requests.progress'),
      hideOnForm: true,
      hideOnSidePanel: true,
      editable: false,
      width: 200,
      filterable: false,
      sortable: false,
      isRetrieving: (dataItem) => !hasRequestSummary(dataItem),
      type: 'string'
    },
    {
      id: 20,
      description: 'team_target_hours',
      title: I18n.t('requests.team_target_hours'),
      hideOnForm: true,
      hideOnSidePanel: true,
      editable: false,
      width: 200,
      filterable: false,
      sortable: false,
      isRetrieving: (dataItem) => !hasRequestSummary(dataItem),
      type: 'numeric'
    },
    {
      id: 7,
      description: 'discipline',
      forceUseDescription: true,
      field: 'discipline.description',
      title: I18n.t('requests.discipline'),
      foreignKey: 'disciplines',
      foreignAttribute: 'discipline_id',
      filterable: true,
      required: true,
      editable: false,
      orderOnForm: 4,
      orderOnSidePanel: 4,
      sidePanelFull: true,
      width: 200,
      type: 'drop'
    },
    {
      id: 6,
      description: 'reason',
      title: I18n.t('requests.reason'),
      type: 'string',
      orderOnForm: 3,
      required: true,
      editable: false,
      hideOnSidePanel: true,
      width: 240
    },
    {
      id: 8,
      description: 'comments',
      title: I18n.t('requests.comments'),
      width: 270,
      type: 'multiline_text',
      editable: false,
      hideOnSidePanel: true,
      orderOnForm: 5,
      maxLenght: 255
    },
    {
      id: 10,
      description: 'area',
      forceUseDescription: true,
      field: 'area.description',
      title: I18n.t('requests.area'),
      validation: {
        required: true
      },
      foreignKey: 'areas',
      foreignAttribute: 'area_id',
      filterable: true,
      editable: false,
      required: true,
      width: 200,
      orderOnForm: 6,
      orderOnSidePanel: 2,
      type: 'drop'
    },
    {
      id: 4,
      description: 'service_desired_date',
      title: I18n.t('requests.service_desired_date'),
      default: normalizeDateToString(new Date()),
      validation: { required: true },
      required: true,
      orderOnForm: 2,
      editable: false,
      orderOnSidePanel: 1,
      width: 200,
      type: 'date'
    },
    {
      id: 3,
      description: 'request_date',
      title: I18n.t('requests.request_date'),
      editable: false,
      default: normalizeDateToString(new Date()),
      readOnly: true,
      orderOnSidePanel: 0,
      orderOnForm: 1,
      width: 200,
      type: 'date',
      required: true
    },
    {
      id: 11,
      description: 'subarea',
      forceUseDescription: true,
      title: I18n.t('requests.subarea'),
      foreignKey: 'subareas',
      field: 'subarea.description',
      foreignAttribute: 'subarea_id',
      filterable: true,
      editable: false,
      type: 'cascade_drop',
      orderOnForm: 7,
      orderOnSidePanel: 3,
      dependency: 'area'
    },
    {
      id: 12,
      description: 'information',
      title: I18n.t('requests.information'),
      type: 'multiline_text',
      orderOnForm: 8,
      editable: false,
      orderOnSidePanel: 7,
      sidePanelFull: true,
      width: 200
    },
    {
      id: 13,
      description: 'eav_template_id',
      title: I18n.t('requests.eav_template'),
      hideOnGrid: true,
      hideOnSummary: true,
      hideOnSidePanel: true,
      hideOnForm: true,
      editable: false,
      visible: false,
      defaultValue: 'templateId',
      width: 200,
      type: 'numeric'
    },
    {
      id: 15,
      description: 'estimates_authorization',
      title: I18n.t('requests.estimates_authorization'),
      hideOnSummary: true,
      hideOnSidePanel: true,
      hideOnUpdate: function fn(dataItem) {
        if (
          this.statuses &&
          this.statuses[dataItem.request_status_id] &&
          this.statuses[dataItem.request_status_id].i18n_id === 'registered'
        )
          return false
        return true
      }.bind(this),
      default: false,
      type: 'boolean',
      editable: false,
      orderOnForm: 9,
      width: 200
    },
    {
      id: 16,
      description: 'skip_scoping',
      title: I18n.t('requests.skip_scoping'),
      hideOnSummary: true,
      hideOnUpdate: function fn(dataItem) {
        if (
          this.statuses &&
          this.statuses[dataItem.request_status_id] &&
          this.statuses[dataItem.request_status_id].i18n_id === 'registered'
        )
          return false
        return true
      }.bind(this),
      default: false,
      orderOnForm: 10,
      orderOnSidePanel: 6,
      editable: false,
      sidePanelFull: true,
      type: 'boolean'
    }
  ]

  columns = includeDateColumns(this.initialColumns)

  setStatuses = (requestStatuses) => {
    this.statuses = requestStatuses
  }

  dropForeignColumns = foreignColumns(this.columns)
}

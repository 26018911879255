import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { formatNumberWithPrecision } from '/src/utils/project_formatter'
import { isBlank } from '/src/utils/boolean_refinements'

export default function DeltaHoursColumn({ data }) {
  const [globalProject] = useStore('project')
  return deltaHours(data, globalProject)
}

DeltaHoursColumn.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object])
}

DeltaHoursColumn.defaultProps = {
  data: {}
}

export const deltaHours = (data, project) => {
  if (isBlank(data) || isBlank(data.progress_summary)) return '---'
  if (isBlank(data.progress_summary.actual_hours)) return '---'
  if (isBlank(data.progress_summary.progress_hours)) return '---'

  return formatNumberWithPrecision(data.progress_summary.actual_hours - data.progress_summary.progress_hours, project)
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from '@progress/kendo-react-dialogs'
import I18n from '/src/utils/translations'
import CancelButton from '/src/ui/core/buttons/cancel_button'
import InputDrop from '/src/ui/core/inputs/input_drop'
import InputTitle from '/src/ui/core/inputs/input_title'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import { MEDIUM_POPUP_HEIGHT, MEDIUM_POPUP_WIDTH } from '/src/utils/constants/popups'
import '/src/static/css/dialogs.css'
import '/src/static/css/core/popups/configure_print_grid_file.css'

const pageBreakMap = {
  [I18n.t('actions.yes')]: true,
  [I18n.t('actions.no')]: false
}

const textSizesMap = {
  [I18n.t('dialogs.configure_print_list_file.small')]: 0.5,
  [I18n.t('dialogs.configure_print_list_file.medium')]: 0.6,
  [I18n.t('dialogs.configure_print_list_file.large')]: 0.75
}

const YES_NO = Object.keys(pageBreakMap)
const TEXT_SIZES = Object.keys(textSizesMap)

export default function ConfigurePrintListFile({ model, gridData, onClose }) {
  const [pageBreak, setPageBreak] = useState(YES_NO[0])
  const [textSize, setTextSize] = useState(TEXT_SIZES[1])

  const onChangeForcePageBreak = (_, inputValue) => setPageBreak(inputValue)
  const onChangeTextSize = (_, inputValue) => setTextSize(inputValue)


  const onGenerateFile = () => {
    const forcePageBreak = pageBreakMap[pageBreak]
    const scale = textSizesMap[textSize]

    const pdfConfig = { forcePageBreak, scale }

    dispatch({
      type: BusEvents.PRINT_LIST,
      payload: { model, gridData, pdfConfig }
    })
    onClose()
  }

  return (
    <React.Fragment>
      <Dialog
        className="configure-print-grid-file"
        title={I18n.t('dialogs.configure_print_list_file.header')}
        closeIcon
        onClose={onClose}
        width={MEDIUM_POPUP_WIDTH}
        height={MEDIUM_POPUP_HEIGHT}
      >
        <div className="title">
          <span>{I18n.t('dialogs.configure_print_grid_file.title')}</span>
        </div>

        <div className='configure-print-grid-file__spaced-inputs'>
          <div className='configure-print-grid-file__input' data-testid='page-break-input'>
            <InputTitle title={I18n.t('dialogs.configure_print_list_file.page_break_title')} required />
            <InputDrop
              inputProps={{
                id: 'page_break',
                title: I18n.t('dialogs.configure_print_list_file.page_break_title'),
                options: YES_NO,
                value: pageBreak,
                onChange: onChangeForcePageBreak,
                required: true,
                preventNull: true
              }}
            />
          </div>

          <div className='configure-print-grid-file__input' data-testid='font-size-input'>
            <InputTitle title={I18n.t('dialogs.configure_print_list_file.font_size_title')} required />
            <InputDrop
              inputProps={{
                id: 'text_size',
                title: I18n.t('dialogs.configure_print_list_file.font_size_title'),
                options: TEXT_SIZES,
                value: textSize,
                onChange: onChangeTextSize,
                required: true,
                preventNull: true
              }}
            />
          </div>
        </div>

        <div className="action-buttons">
          <CancelButton title={I18n.t('actions.cancel')} onClick={onClose} />
          <button type='button' className="generate-button" onClick={onGenerateFile}>
            {I18n.t('dialogs.configure_print_grid_file.generate')}
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  )
}

ConfigurePrintListFile.propTypes = {
  onClose: PropTypes.func.isRequired,
  gridData: PropTypes.oneOfType([PropTypes.array]).isRequired,
  model: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
}

ConfigurePrintListFile.defaultProps = {

}

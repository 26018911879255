/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Modal from '/src/ui/core/popups/modal'
import { isEmpty } from '/src/utils/object'
import {
  getCount,
  getSubtitle,
  buildRouteAndQuery,
  filterPreviousChoices
} from '/src/utils/inspected_module_filter_utils'
import FilterResults from '/src/ui/domain/inspections/popups/filter_results'
import ModalSearchInput from '/src/ui/domain/inspections/popups/modal_search_input'
import ModalActionButtons from '/src/ui/domain/inspections/popups/modal_action_buttons'
import useInspectionTemplateFetches from '/src/hooks/use_inspection_template_fetches'
import useJumpFilterSteps from '/src/hooks/use_jump_filter_steps'
import '/src/static/css/core/popups/filter_steps_modal.css'

// eslint-disable-next-line max-lines-per-function
export default function InspectedModuleFilterStepsModal({
  filters,
  currentValues,
  displayParentValues,
  onDone,
  onClose,
  inspectionDetail
}) {
  const [values, setValues] = useState(currentValues)
  const [selectedValue, setSelectedValue] = useState(currentValues?.inspected_type)

  const [displayValues, setDisplayValues] = useState(displayParentValues)
  const [selectedDisplayValue, setSelectedDisplayValue] = useState(displayParentValues?.inspected_type)

  const [index, setIndex] = useState(0)
  const [stepStatus, setStepStatus] = useState('loading')
  const [responseData, setResponseData] = useState([])

  const dropRef = useRef(null)

  const {
    inspectedColumn,
    isLoadingTemplatesOrColumns,
    fetchColumns,
    fetchTemplates,
    getTextDisplayAndSearchFields
  } = useInspectionTemplateFetches()

  const { findJump, findLastJump } = useJumpFilterSteps(filters.length)

  const currentFilter = filters[index]
  const totalSteps = filters.length + 1
  const isLastStep = index === totalSteps - 1

  const isLoading = stepStatus !== 'loaded'

  const moveStep = (newIndex, newValues = values, newDisplayValues = displayValues) => {
    setIndex(newIndex)

    if (newIndex >= filters.length) return

    setStepStatus('loading')

    const filter = filters[newIndex]?.description
    setSelectedValue(newValues[filter])
    setSelectedDisplayValue(newDisplayValues[filter])
  }

  const toNextStep = (filterValue, filterDisplayValue) => {
    const currentSelectedValue = filterValue ?? selectedValue
    const currentSelectedDisplayValue = filterDisplayValue ?? selectedDisplayValue

    const { description } = currentFilter

    if (description === 'inspected_template') {
      fetchColumns(currentSelectedValue)
      fetchTemplates(currentSelectedValue)
    }

    const newValues = { ...values, [description]: currentSelectedValue }
    const newDisplayValues = { ...displayValues, [description]: currentSelectedDisplayValue }

    const [filteredValues, filteredDisplayValues] = filterPreviousChoices(
      index,
      newValues,
      newDisplayValues,
      currentSelectedValue
    )

    setValues(filteredValues)
    setDisplayValues(filteredDisplayValues)

    const newIndex = findJump(index, currentSelectedValue, values?.inspected_type)
    moveStep(newIndex, filteredValues, filteredDisplayValues)
  }

  const toPreviousStep = () => {
    const newIndex = findLastJump(index)
    moveStep(newIndex)
  }

  const doneClick = () => onDone(values, displayValues)

  const onSelectedValue = (_ignored, value, itemValue) => {
    setSelectedValue(value)

    if (currentFilter.description === 'service_ids') {
      const formattedValues = itemValue.map(({ computed_text_field: computedTextId, grade }) => ({
        computedTextId,
        grade,
      }))
      
      setSelectedDisplayValue(formattedValues)
      return
    }

    if (value && selectedValue !== value) {
      const itemDisplayValue = itemValue?.computed_text_field ?? value
      toNextStep(value, itemDisplayValue)
    }
  }

  const onFetch = (fetchedData) => {
    setResponseData(fetchedData)
    setStepStatus('loaded')
    dropRef.current?.focus()
  }

  const props = {
    className: 'selector-combobox',
    popupClassName: 'filters-steps-combobox-list',
    iconClassName: 'k-i-search',
    onChange: onSelectedValue,
    onFetch,
    ref: dropRef,
    appendTo: '#filter-steps-options',
    forceCombobox: true,
    readOnly: false,
    opened: true
  }

  const [inputProps, setInputProps] = useState(props)

  // builds inputProps for next fetch
  // this is only entered when the index changes, or when the isLoadingTemplatesOrColumns changes
  useEffect(() => {
    if (isLastStep || isLoadingTemplatesOrColumns) return

    const filter = currentFilter
    const isInspectedIdStep = currentFilter.description === 'inspected_id'

    const [searchRoute, searchExtraQuery] = buildRouteAndQuery(filter, values, inspectionDetail)

    if (!searchRoute && filter.metadata) {
      setResponseData(JSON.parse(filter.metadata))
      setStepStatus('loaded')
      dropRef.current?.focus()
    }

    const [textDisplayFields, searchFields] = isInspectedIdStep
      ? getTextDisplayAndSearchFields(filter, values?.inspected_type)
      : [filter.textDisplayFields, filter.searchFields]

    setInputProps({
      ...props,
      searchRoute,
      searchFields,
      searchExtraQuery,
      textDisplayFields,
      metadata: filter.metadata,
      multiple: filter.multiple,
      distinct: filter.distinct,
      type: filter.type,
      value: values[filter.description],
      index
    })
  }, [values, index, filters, inspectedColumn, isLoadingTemplatesOrColumns])

  return (
    <Modal
      loading={{ status: stepStatus === 'loading', text: I18n.t('main_dialog.loading_title') }}
      height={90}
      width={50}
      title={`${I18n.t('filter_steps_modal.step')} ${index + 1}/${totalSteps}`}
      onClose={onClose}
      className="k-window-wrapper modal-wrapper"
      appendToBody
      closable
    >
      <div key={index} id="filter-steps-modal" className="filter-steps-modal">
        <div id="filter-steps-title" className="filter-steps-title">
          {isLastStep ? I18n.t('filter_steps_modal.filter_result') : currentFilter?.title}
          <span className="count">
            {getCount(index, inspectionDetail, responseData)}
          </span>
        </div>

        <div id="filter-steps-subtitle" className="filter-steps-subtitle">
          {getSubtitle(isLastStep, currentFilter?.title)}
        </div>

        <div id="filter-steps-content" className="filter-steps-content">
          {isLastStep ? (
            <FilterResults
              values={values}
              filters={filters}
              displayValues={displayValues}
              displayParentValues={displayParentValues}
            />
          ) : (
            <React.Fragment>
              <ModalSearchInput
                index={index}
                values={values}
                inputProps={inputProps}
                inspectionDetail={inspectionDetail}
              />
              <div id="filter-steps-options" className="filter-steps-options" />
            </React.Fragment>
          )}
        </div>

        <ModalActionButtons
          index={index}
          isLoading={isLoading}
          isLastStep={isLastStep}
          handleDoneClick={doneClick}
          handlePreviousStep={() => toPreviousStep()}
          handleNextStep={() => toNextStep(selectedValue, selectedDisplayValue)}
          isNextButtonDisabled={isEmpty(responseData) || !selectedValue}
        />
      </div>
    </Modal>
  )
}

InspectedModuleFilterStepsModal.propTypes = {
  filters: PropTypes.oneOfType([PropTypes.array]).isRequired,
  currentValues: PropTypes.oneOfType([PropTypes.object]),
  displayParentValues: PropTypes.oneOfType([PropTypes.object]),
  onDone: PropTypes.func,
  onClose: PropTypes.func,
  inspectionDetail: PropTypes.shape({
    inspection_disciplines: PropTypes.arrayOf(PropTypes.number),
    inspection_modules: PropTypes.arrayOf(PropTypes.string)
  })
}

InspectedModuleFilterStepsModal.defaultProps = {
  currentValues: {},
  displayParentValues: {},
  onDone: () => { },
  onClose: () => { },
  inspectionDetail: {}
}

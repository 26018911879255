import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import InputTitle from '/src/ui/core/inputs/input_title'
import { isBlank } from '/src/utils/boolean_refinements'

// eslint-disable-next-line max-lines-per-function
export default function Field({ column, renderColumn, dataItem }) {
  const {
    description,
    title,
    required,
    visible_on_web: visible,
    type,
    fieldClass,
    elementBefore,
    elementAfter,
    read_only: readOnly,
    information,
    subtitle,
    column_type
  } = column

  const isRequired = typeof required === 'function' ? required(dataItem) : required

  const shouldHide = visible === false && !isRequired
  const noHeightTypes = ['datasheet_filter', 'multiple_datasheet_filter']
  const isFixed = isBlank(column_type)
  const renderedColumn = renderColumn(column)

  const sectionClasses = classNames({
    'section-field': true,
    'dont-grow': column.dontExpand,
    [column.columnSize]: true,
    [fieldClass]: fieldClass,
    invisible: shouldHide,
    hidden: shouldHide && isFixed,
    'column-height': !noHeightTypes.includes(type),
    'input-not-editable': readOnly === true
  })

  return (
    renderedColumn !== null && (
      <React.Fragment>
        {elementBefore}
        <div className={sectionClasses}>
          <InputTitle htmlFor={description} title={title} information={information} required={isRequired}>
            {subtitle && <div className="column-label-subtitle">{subtitle}</div>}
          </InputTitle>
          {renderedColumn}
        </div>
        {elementAfter}
      </React.Fragment>
    )
  )
}

Field.propTypes = {
  column: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
    title: PropTypes.string,
    information: PropTypes.string,
    subtitle: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    visible_on_web: PropTypes.bool,
    dontExpand: PropTypes.bool,
    columnSize: PropTypes.string,
    type: PropTypes.string,
    fieldClass: PropTypes.string,
    elementBefore: PropTypes.element,
    elementAfter: PropTypes.element,
    read_only: PropTypes.bool
  }).isRequired,
  renderColumn: PropTypes.func.isRequired,
  dataItem: PropTypes.object
}

Field.defaultProps = {
  dataItem: undefined
}

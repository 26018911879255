import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { DateRangePicker } from '@progress/kendo-react-dateinputs'
import { MdDateRange } from 'react-icons/md'
import { getFormatLocaleDate, normalizeDateToString, normalizeStringToLocalDate } from '/src/utils/project_formatter'
import I18n from '/src/utils/translations'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'
import { isPresent } from '/src/utils/boolean_refinements'
import '/src/static/css/input_date.css'

export default function InputDateRange({ inputProps }) {
  const { id, className, title, required, type, value, onChange, simplified } = inputProps
  const [iconClass, setIconClass] = useState('calendar-icon')
  const [show, setShow] = useState(false)
  const [project] = useStore('project')

  const startPlaceholder = I18n.t(`form.inputs.date_range.start${simplified ? '_simplified' : ''}`)
  const endPlaceholder = I18n.t(`form.inputs.date_range.end${simplified ? '_simplified' : ''}`)

  const valueFormatter = (input) => {
    return { start: normalizeDateToString(input.start), end: normalizeDateToString(input.end) }
  }

  const dateFormatter = (dateRange) => {
    let start = null
    let end = null

    if (dateRange) {
      start = normalizeStringToLocalDate(dateRange.start)
      if (isNaN(start.getTime()) || dateRange.start === null) start = null

      end = normalizeStringToLocalDate(dateRange.end)
      if (isNaN(end.getTime()) || dateRange.end === null) end = null
    }

    return { start, end }
  }

  const [inputValue, onInputChange] = useInputChange({ id, value, onChange, valueFormatter })
  const error = useInputError({ inputValue, title, required, type })
  const format = getFormatLocaleDate(project)
  const startDateInputSettings = {
    label: '',
    formatPlaceholder: { day: ' ', month: ' ', year: startPlaceholder },
    format: inputValue && isPresent(inputValue.start) ? format : 'dMy'
  }

  const endDateInputSettings = {
    label: '',
    formatPlaceholder: { day: endPlaceholder, month: ' ', year: ' ' },
    format: inputValue && isPresent(inputValue.end) ? format : 'dMy'
  }

  const inputClass = () => {
    const classes = ['date-range-picker']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  const onFocus = () => {
    setShow(true)
    setIconClass('calendar-icon focused')
  }

  const onBlur = () => {
    setShow(false)
    setIconClass('calendar-icon')
  }

  return (
    <div className="date-range-picker-wrapper">
      <div className="date-range-and-button">
        <DateRangePicker
          value={dateFormatter(inputValue)}
          className={inputClass()}
          startDateInputSettings={startDateInputSettings}
          endDateInputSettings={endDateInputSettings}
          onChange={(e) => {
            const { start, end } = e.value
            if (start && end) onBlur()
            onInputChange(e)
          }}
          show={show}
          onFocus={onFocus}
          onBlur={onBlur}
          calendarSettings={{ views: 1 }}
        />

        <button type="button" className={iconClass} onClick={() => setShow((shown) => !shown)}>
          <MdDateRange />
        </button>
      </div>
      <div className="error-label">{error}</div>
    </div>
  )
}

InputDateRange.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func,
    title: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  }).isRequired
}

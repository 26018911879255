import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import useBatchEntities from '/src/hooks/api/batch_entities'
import useFieldSettings from '/src/hooks/field_settings'
import SectionedPanel from '/src/ui/core/sectioned_panel/sectioned_panel'
import ColumnInput from '/src/ui/core/inputs/column_input'
import FixedSections from '../sectioned_panel/fixed_sections'
import LoadingCover from '/src/ui/core/layouts/loading_cover'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import useSendForm from '/src/ui/core/forms/send_form_hook'
import useSetupFormulas from '/src/ui/core/forms/setup_formulas'
import useFieldsControl from '/src/ui/core/forms/fields_control_hook'
import useFormulasCalculation from '/src/ui/core/forms/formulas_calculation_hook'
import useManageDependencies from '/src/ui/core/forms/manage_dependencies'
import useManageFixedForm from '/src/ui/core/forms/manage_fixed_form'
import useParentItemsRegister from '/src/hooks/parent_items_register'
import { sortArrayOfObjectsByNumber } from '/src/utils/object'
import useSetupDatasheetFilter from '/src/ui/core/inputs/setup_datasheet_filter'
import { isPresent } from '/src/utils/boolean_refinements'
import I18n from '/src/utils/translations'
import { indexify } from '/src/utils/array'
import { DROP_COLUMN_TYPES } from '/src/utils/constants/columns'
import { isSectionVisible, sectionsColumnsNotExpandable } from '/src/models/concerns/eav_section'
import { isFormulaServiceHidden } from '/src/models/concerns/formula'
import { storeInitialVisibleOnWeb, isFixedForm } from '/src/ui/core/forms/form_utils'

const startIndexEavSections = (fixedSections, fixedColumns) => {
  if (fixedSections) return fixedSections.length
  return isSectionVisible(fixedColumns) ? 1 : 0
}

export default function Form({
  model,
  sections,
  dataItem,
  includeOnForm,
  templateId,
  sectionable,
  hideable,
  type,
  disableColumnEdition,
  submitParams,
  errorHandler,
  onFormSubmitAction,
  hasFormulasServices,
  formulasServices,
  processColumn,
  onChangeColumn,
  pollingCallback,
  onSubmitCallback,
  eavTemplate,
  isDuplicate,
  customDispatchEvent,
  onSuccess
}) {
  const clearedDataItem = isDuplicate ? { ...dataItem, attachments: [], pictures: [], signatures: [] } : dataItem
  const formRef = useRef(null)
  const [fixedFields, setFixedFields] = useState()
  const [eavColumns, setEavColumns] = useState({})
  const [formComponents, setFormComponents] = useState()
  const { register, handleSubmit, setValue, control, getValues, watch } = useForm()
  const { paramName, columns, route, formulas: modelFormulas } = model
  const fixedForm = isFixedForm(paramName)
  const [formulas, formulasControlFields, loadingFormulas, treatFormulaFields] = useSetupFormulas(
    templateId,
    paramName,
    modelFormulas
  )
  const compoundDataItem = clearedDataItem !== null ? { ...clearedDataItem, route } : null
  const formTypes = { new: 'create', edit: 'update' }
  const action = formTypes[type] || type
  const sendFormProps = {
    model,
    action,
    dataItem: clearedDataItem,
    eavColumns,
    submitParams,
    errorHandler,
    onFormSubmitAction,
    pollingCallback,
    customDispatchEvent,
    onSuccess
  }
  const [sendFormData] = useSendForm(sendFormProps)
  const [
    datasheetFilters,
    multipleDatasheetFilters,
    loadingDatasheetFilters,
    treatDatasheetFilterColumns
  ] = useSetupDatasheetFilter(templateId, clearedDataItem, setValue)

  const fieldSettings = useFieldSettings(templateId, ['hide_on_form'])

  const foreignEntities = columns.reduce((filtered, column) => {
    const { foreignKey, query } = column
    if (foreignKey && DROP_COLUMN_TYPES.includes(column.type)) filtered.push({ foreignKey, query })
    return filtered
  }, [])

  const loadingBatch = useBatchEntities(foreignEntities, fixedForm)
  const batchedEntities = useEntitiesCache(foreignEntities)

  // the parentItems are used to calculate equations with parent columns inside
  const parentItems = useParentItemsRegister(clearedDataItem, model, control, register, setValue)

  const onSetFixedFields = (fields) => {
    setFixedFields(sortArrayOfObjectsByNumber(fields, 'orderOnForm'))
  }

  useFieldsControl(watch, formulasControlFields, eavColumns, setEavColumns, fixedFields, onSetFixedFields, parentItems)

  const formulaResultsParams = {
    watch,
    formulas,
    eavColumns,
    fixedFields,
    parentItems,
    includeOnForm,
    dataItem: clearedDataItem
  }

  const formulasResults = useFormulasCalculation(formulaResultsParams)
  const { finishedInitialLoad, checkDependencies } = useManageDependencies({ fixedForm })

  useBus(
    BusEvents.FORM_SUBMIT,
    () => {
      dispatch(BusEvents.INPUT_VALIDATE)
      if (onSubmitCallback) return onSubmitCallback(getValues())
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))
    },
    [formRef]
  )

  useEffect(() => {
    const hasDependeciesLoading = checkDependencies(
      loadingBatch,
      sections,
      loadingFormulas,
      loadingDatasheetFilters,
      formulasServices,
      templateId,
      hasFormulasServices
    )
    if (hasDependeciesLoading) return

    registerAndStoreFixedFields(formulasResults)
    registerAndStoreFlexibleFields(formulasResults)
  }, [
    loadingBatch,
    sections,
    loadingFormulas,
    formulas,
    formulasResults,
    loadingDatasheetFilters,
    formulasServices,
    checkDependencies,
    templateId,
    hasFormulasServices
  ])

  useEffect(() => {
    if (Object.values(includeOnForm).length === 0) return
    Object.keys(includeOnForm).forEach((key) => {
      register(key)
      setValue(key, includeOnForm[key])
    })
  }, [includeOnForm])

  useEffect(() => {
    if (fixedFields) buildRender()
  }, [fixedFields, eavColumns])

  const treatForeignDrop = (column) => {
    if (column.foreignKey) {
      column.description = column.foreignAttribute
      if (DROP_COLUMN_TYPES.includes(column.type)) {
        column.metadata = JSON.stringify(Object.values(batchedEntities[column.foreignKey]))
      }
    }

    return column
  }

  const shouldShowField = (column) => {
    const name = column.description
    let { hideOnForm } = column

    const columnValues = getValues()

    hideOnForm = typeof hideOnForm === 'function' ? hideOnForm({ ...clearedDataItem, ...columnValues }) : hideOnForm

    if (column.type === 'formula_service' && isPresent(formulasServices)) {
      hideOnForm = !(formulasServices[`${name}_visible`] && !hideOnForm)
    }

    if (clearedDataItem && !hideOnForm && type === 'edit' && column.hideOnUpdate)
      hideOnForm = column.hideOnUpdate(clearedDataItem)

    const setting = fieldSettings[column.foreignAttribute] || fieldSettings[name]

    if (setting) hideOnForm = hideOnForm || setting.hide_on_form

    hideOnForm = hideOnForm || isFormulaServiceHidden(name, formulasServices)

    return !hideOnForm
  }

  const registerFieldsOnFormHook = (cols) => {
    cols.forEach((column) => {
      const id = column.description

      if (getValues(id) !== undefined) return
      register(id)
      if (type === 'new' && column.default) {
        let defaultValue = column.default
        const numberTypes = ['integer', 'decimal', 'percentage']
        if (column.column_type && numberTypes.includes(column.column_type.description))
          defaultValue = parseFloat(column.default)

        setValue(id, defaultValue)
      }

      if (clearedDataItem && clearedDataItem[id] !== undefined) setValue(id, clearedDataItem[id])
    })
  }

  const virtualFields = (cols) => cols.filter((c) => c.virtualField)

  const registerAndStoreFixedFields = (results) => {
    const duplicatedFields = ['request_id', 'responsible_id']
    let fields = columns
      .filter((c) => !(c.hideOnForm && duplicatedFields.includes(c.description)))
      .map((c) => {
        let newColumn = { ...c, visible_on_web: shouldShowField(c) }
        newColumn = treatForeignDrop(newColumn)
        return processColumn(newColumn)
      })

    if (formulas) fields = treatFormulaFields(fields, results, setValue)

    registerFieldsOnFormHook([...fields, ...virtualFields(columns)])
    onSetFixedFields(fields)
  }

  const registerAndStoreFlexibleFields = (results) => {
    let flexibleFields = [].concat(...sections.map((s) => s.eav_columns.map((c) => storeInitialVisibleOnWeb(c))))

    if (formulas) flexibleFields = treatFormulaFields(flexibleFields, results, setValue)

    registerFieldsOnFormHook(flexibleFields)
    setEavColumns(indexify(flexibleFields, 'id'))
  }

  const renderColumn = (column) => (
    <ColumnInput
      column={column}
      dataItem={compoundDataItem}
      onChange={setValue}
      control={{ ...control, getValues }}
      disableColumnEdition={disableColumnEdition}
      extraParams={{ eavTemplate }}
    />
  )

  const updateSectionsWithEavColumns = () => {
    return sections.map((section) => {
      section.eav_columns = section.eav_columns.map((column) => eavColumns[column.id])
      return section
    })
  }

  const getSections = () => {
    const sectionsUpdated = updateSectionsWithEavColumns()

    const sectionsToRender =
      !datasheetFilters && !multipleDatasheetFilters
        ? sectionsUpdated
        : treatDatasheetFilterColumns(sectionsUpdated, formulasControlFields)

    return sectionsColumnsNotExpandable(sectionsToRender)
  }

  const shouldRenderColumn = (column) => {
    const { type: columnType, formula } = column

    if (columnType === 'rich_text' && eavTemplate) return eavTemplate.enable_rich_text

    if (columnType !== 'formula_service') return true

    return isPresent(formula)
  }

  const onChangeFixedColumn = (id, value) => {
    setValue(id, value)
    onChangeColumn(id, value)
  }

  const renderFixedColumn = (column) => {
    return shouldRenderColumn(column) ? (
      <ColumnInput
        column={column}
        dataItem={clearedDataItem}
        onChange={onChangeFixedColumn}
        control={{ ...control, getValues }}
        disableColumnEdition={disableColumnEdition}
        extraParams={{ eavTemplate }}
      />
    ) : null
  }

  const buildRender = () => {
    const fixedSections =
      fixedFields.length > 0 ? (
        <FixedSections
          key={fixedFields}
          sections={model.sections}
          fixedFields={fixedFields}
          sectionable={sectionable}
          hideable={hideable}
          renderColumn={renderFixedColumn}
          dataItem={clearedDataItem}
        />
      ) : (
        ''
      )

    const sectionedPanel =
      sections.length > 0 ? (
        <SectionedPanel
          key="sections"
          sections={getSections()}
          hideable
          enumerable
          sectionable={sectionable}
          renderColumn={renderColumn}
          startIndex={startIndexEavSections(model.sections, fixedFields)}
        />
      ) : (
        ''
      ) // TODO: Add a nice component to display that the template has no sections

    setFormComponents([fixedSections, sectionedPanel])
  }

  useManageFixedForm({ registerAndStoreFixedFields, fixedForm, control, columns })

  return formComponents && finishedInitialLoad ? (
    <form key={templateId} ref={formRef} onSubmit={handleSubmit((data) => sendFormData(data))}>
      {formComponents}
    </form>
  ) : (
    <LoadingCover show text={I18n.t('form.loading_calculations')} className="form-loading" />
  )
}

Form.propTypes = {
  model: PropTypes.oneOfType([PropTypes.object]).isRequired,
  sections: PropTypes.oneOfType([PropTypes.array]),
  templateId: PropTypes.number,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  includeOnForm: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.string,
  disableColumnEdition: PropTypes.func,
  onChangeColumn: PropTypes.func,
  submitParams: PropTypes.shape({
    requestAction: PropTypes.string,
    httpAction: PropTypes.string,
    resourceId: PropTypes.number,
    additionalResource: PropTypes.oneOfType([PropTypes.object])
  }),
  errorHandler: PropTypes.func,
  sectionable: PropTypes.bool,
  hideable: PropTypes.bool,
  onFormSubmitAction: PropTypes.func,
  noBatch: PropTypes.bool,
  hasFormulasServices: PropTypes.bool,
  formulasServices: PropTypes.oneOfType([PropTypes.object]),
  processColumn: PropTypes.func,
  pollingCallback: PropTypes.func,
  onSubmitCallback: PropTypes.func,
  eavTemplate: PropTypes.oneOfType([PropTypes.object]),
  isDuplicate: PropTypes.bool,
  customDispatchEvent: PropTypes.string,
  onSuccess: PropTypes.func
}

Form.defaultProps = {
  templateId: 0,
  dataItem: null,
  sections: [],
  includeOnForm: {},
  type: 'new',
  disableColumnEdition: () => {},
  onChangeColumn: () => {},
  submitParams: undefined,
  errorHandler: undefined,
  sectionable: true,
  hideable: true,
  onFormSubmitAction: undefined,
  noBatch: false,
  hasFormulasServices: false,
  formulasServices: null,
  processColumn: (column) => column,
  pollingCallback: null,
  onSubmitCallback: null,
  eavTemplate: null,
  isDuplicate: false,
  customDispatchEvent: undefined,
  onSuccess: undefined
}

export const fieldSizeByType = (column, dataItem) => {
  const { column_type: columnType, web_form_width: formWidth, filters, initialVisibleOnWeb, required } = column

  const isDatasheetRequired = filters && filters[0] && filters[0].required
  const isRequired = isDatasheetRequired || (typeof required === 'function' ? required(dataItem) : required)

  if (initialVisibleOnWeb === false && !isRequired) return 'width-0'

  const isDatasheetFilterSingle = columnType && columnType.single

  const sizeByType = {
    // Column Types
    boolean: 'width-50',
    string: 'width-50',
    text: 'width-50',
    integer: 'width-50',
    decimal: 'width-50',
    numeric: 'width-50',
    percentage: 'width-50',
    date: 'width-50',
    date_time: 'width-50',
    gps: 'width-50',
    lookup: 'width-50',
    link: 'width-50',
    drop: 'width-50',
    multiple_drop: 'width-50',
    time: 'width-50',
    picture: 'width-100',
    fixed_picture: 'width-100',
    multiline_text: 'width-100',
    formula: 'width-100',
    attachment: 'width-100',
    datasheet_filter: isDatasheetFilterSingle ? 'width-50' : 'width-100',
    drop_formula: 'width-100',

    // Special Types
    date_range: 'width-50',
    cascade_drop: 'width-50',
    conditional_cascade_drop: 'width-50',
    search: 'width-50',
    module_filter: 'width-100',
    formula_service: 'width-100',
    upload: 'width-100',
    contract_service: 'width-100',
    controlled_formula: 'width-100',
    controlled_drop_formula: 'width-100',
    multiple_entries: 'width-100',
    rich_text: 'width-100'
  }

  const datasheetFilterWidth = filters && filters[0] && filters[0].web_form_width
  const columnFormWidth = datasheetFilterWidth || formWidth

  const widthByType = sizeByType[column.type]
  return columnFormWidth ? `width-${columnFormWidth}` : widthByType
}

export const fieldWidthByType = (type) => {
  switch (type) {
    case 'date_time':
      return 250
    default:
      return 150
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { categorizeColumnsByType } from '/src/utils/pdf_utils'
import useFirstFlexibleColumn from '/src/hooks/first_flexible_column'
import PrintListPDFTitle from '/src/ui/core/grid/pdf_print/print_list_pdf_title'
import PrintListPDFOtherColumns from '/src/ui/core/grid/pdf_print/print_list_pdf_other_columns'
import PrintListPDFMultilineColumns from '/src/ui/core/grid/pdf_print/print_list_pdf_multiline_columns'
import PrintListPDFPictureColumns from '/src/ui/core/grid/pdf_print/print_list_pdf_picture_columns'

export default function PrintListPDFBody({ gridData, gridColumns, storeValues, modelName }) {
  const filterFirstFlexibleColumn = useFirstFlexibleColumn()
  const firstFlexibleColumn = filterFirstFlexibleColumn(gridColumns)

  const { otherColumns, multilineTextColumns, pictureColumns } = categorizeColumnsByType(gridColumns)

  return gridData.map((item, index) => (
    <div className={`print-list-pdf__item ${index === 0 ? '' : 'page-break'}`} key={item.id}>
      <div className='print-list-pdf__item-dot'>
        {index + 1}
      </div>

      <PrintListPDFTitle
        item={item}
        modelName={modelName}
        storeValues={storeValues}
        firstFlexibleColumn={firstFlexibleColumn}
      />

      <PrintListPDFOtherColumns
        item={item}
        project={storeValues.project}
        columns={otherColumns}
      />

      <PrintListPDFMultilineColumns
        item={item}
        columns={multilineTextColumns}
      />

      <PrintListPDFPictureColumns
        item={item}
        storeValues={storeValues}
        columns={pictureColumns}
      />
    </div>
  ))
}

PrintListPDFBody.propTypes = {
  gridData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  gridColumns: PropTypes.arrayOf(PropTypes.shape({
    column_type: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string
    })
  })).isRequired,
  storeValues: PropTypes.shape({
    project: PropTypes.oneOfType([PropTypes.object]),
    subproject: PropTypes.oneOfType([PropTypes.object]),
    units: PropTypes.oneOfType([PropTypes.object]),
    progressServiceStatuses: PropTypes.oneOfType([PropTypes.object])
  }).isRequired,
  modelName: PropTypes.string.isRequired
}

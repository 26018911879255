/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useRef } from 'react'
import { useSetStoreValue } from 'react-context-hook'
import { MdLabelOutline, MdAddCircle } from 'react-icons/md'
import PropTypes from 'prop-types'
import { useQueryParam, NumberParam } from 'use-query-params'
import { dispatch } from '/src/hooks/bus/bus'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import useRequestsPinned from '/src/hooks/requests_pinned'
import BusEvents from '/src/hooks/bus/bus_events'
import PopupAnchored from '/src/ui/core/popups/popup_anchored'
import PopupMenuItem from '/src/ui/core/popups/popup_menu_item'
import PopupNewLabel from '/src/ui/core/popups/popup_new_label'
import PopupSearch from '/src/ui/core/popups/popup_search'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useUpdateEffect from '/src/hooks/update_effect'
import I18n from '/src/utils/translations.js'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { contains } from '/src/utils/string'
import '/src/static/css/popup_anchored.css'

export default function PopupScopeLabel({ selectedItems }) {
  const labelsAPI = useFetchAPI('scope_labels')
  const labelAssignerAPI = useFetchAPI('scopes/update_in_batch')
  const [popupOpened, setPopupOpened] = useState(false)
  const [labelButtons, setLabelButtons] = useState(null)
  const [filteredLabels, setFilteredLabels] = useState([])
  const [newLabelOpened, setNewLabelOpened] = useState(false)
  const popupButtonRef = useRef(null)
  const setNotification = useSetStoreValue('notification')
  const [tabbing] = useQueryParam('eav_template_id', NumberParam)
  const { requestId } = useRequestsPinned()

  const newLabelPopup = () => {
    setNewLabelOpened(!newLabelOpened)
  }

  const newLabelButton = {
    text: I18n.t('scopes.popup_label.new_label'),
    icon: <MdAddCircle />,
    onClick: newLabelPopup
  }

  const onChangeSearch = (searchedValue) => {
    if (labelsAPI.responseData.data.length === 0) return []
    const labels = labelsAPI.responseData.data.label.map((label) => ({ description: label }))

    if (searchedValue) return labels.filter((label) => contains(label.description, searchedValue))
    return labels
  }

  const closePopup = () => {
    setNewLabelOpened(false)
    setPopupOpened(false)
  }

  const updateInBatch = (label) => {
    const scopes = {}
    selectedItems.forEach((selected) => {
      scopes[selected.id] = { label }
    })
    const data = { fields: scopes }
    const params = { httpAction: 'put', data }
    labelAssignerAPI.fetchAPI(params)
    closePopup()
  }

  const popupBody = (
    <div className="popup-label">
      <PopupNewLabel
        show={newLabelOpened}
        back={() => setNewLabelOpened(false)}
        close={() => closePopup()}
        applyLabel={updateInBatch}
      />
      <div style={{ display: !newLabelOpened ? 'block' : 'none' }}>
        <div>
          <PopupSearch onChange={(e) => setFilteredLabels(onChangeSearch(e.target.value))} />
        </div>
        <div className="body">
          <p className="labels-header">{I18n.t('scopes.popup_label.labels')}</p>
          <div className="popup-scroll-box" data-testid="popup-scroll-box">
            {labelButtons}
          </div>
        </div>
        <div className="line" />
        <div className="new-label-button">
          <PopupMenuItem item={newLabelButton} key={newLabelButton.text} />
        </div>
      </div>
    </div>
  )

  // Update grid after apply label to selected scope items
  useEffect(() => {
    if (labelAssignerAPI.status !== 'SUCCESS') return
    dispatch(BusEvents.RELOAD_GRID)
  }, [labelAssignerAPI.status])

  const promptSuccessfulLabelAssign = () => {
    const success = {
      title: I18n.t('notification.success'),
      body: I18n.t('scopes.label_assigner.success'),
      status: 'success',
      closable: true,
      closeTimeout: 10
    }
    setNotification(success)
  }

  // open load or success popup dialog
  useUpdateEffect(() => {
    if (labelAssignerAPI.loading && !labelAssignerAPI.errors) return dispatch(BusEvents.SHOW_LOADING_DIALOG)

    dispatch(BusEvents.HIDE_DIALOG)

    if (!labelAssignerAPI.loading && !labelAssignerAPI.errors) {
      promptSuccessfulLabelAssign()
    }
  }, [labelAssignerAPI.loading, labelAssignerAPI.errors])

  // Get labels from API
  useEffect(() => {
    if (popupOpened) {
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { request_id: requestId, eav_template_id: tabbing } }
      }
      labelsAPI.fetchAPI(params)
    }
  }, [popupOpened])

  // Generate label buttons list in the label dropdown
  useEffect(() => {
    if (!popupOpened) return setLabelButtons(null)
    if (labelsAPI.loading) return
    if (labelsAPI.errors) return notifyError(labelsAPI.errors)
    if (!labelsAPI.requestAction) return
    if (labelsAPI.responseData.data.label.length == 0) return
    const items = labelsAPI.responseData.data.label.map((label) => {
      const popupItem = {
        text: label,
        onClick: () => updateInBatch(label)
      }
      return <PopupMenuItem item={popupItem} key={label} />
    })
    setLabelButtons(items)
  }, [labelsAPI.loading, labelsAPI.errors, labelsAPI.requestAction, labelsAPI.responseData, popupOpened])

  // Get filtered labels by the search box
  useEffect(() => {
    if (labelsAPI.responseData.data && labelsAPI.responseData.data.label) {
      setFilteredLabels(onChangeSearch())
    }
  }, [labelsAPI.responseData.data])

  // Generate filtered label buttons list in the label dropdown
  useEffect(() => {
    if (labelsAPI.loading) {
      setLabelButtons(
        <span className="list-info" data-testid="label-result-text">
          {I18n.t('search.loading')}
        </span>
      )
    } else if (filteredLabels.length === 0) {
      setLabelButtons(
        <span className="list-info" data-testid="label-result-text">
          {I18n.t('search.no_result_found')}
        </span>
      )
    } else {
      setLabelButtons(
        filteredLabels.map(({ description }) => {
          const popupItem = {
            text: description,
            onClick: () => updateInBatch(description)
          }
          return <PopupMenuItem item={popupItem} key={description} />
        })
      )
    }
  }, [filteredLabels, labelsAPI.loading])

  return (
    <CustomTooltip position="bottom" parentTitle>
      <PopupAnchored
        body={popupBody}
        popupButtonRef={popupButtonRef}
        forceOpen={popupOpened}
        setForceOpen={() => closePopup()}
      >
        <button
          type="button"
          className={`btn-icon ${popupOpened ? 'active' : ''} grid-header-icon`}
          title={I18n.t('scopes.labels_info')}
          ref={popupButtonRef}
          data-testid="open-popup-button"
          onClick={() => setPopupOpened(!popupOpened)}
        >
          <MdLabelOutline />
        </button>
      </PopupAnchored>
    </CustomTooltip>
  )
}

PopupScopeLabel.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object)
}

PopupScopeLabel.defaultProps = {
  selectedItems: []
}

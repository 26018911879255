import React from 'react'
import { useStore } from 'react-context-hook'
import { render, unmountComponentAtNode } from 'react-dom'
import PropTypes from 'prop-types'
import { savePDF } from '@progress/kendo-react-pdf'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import PrintListPDFBody from '/src/ui/core/grid/pdf_print/print_list_pdf_body'
import { usePDFColumnsAndTemplate } from '/src/utils/pdf_utils'
import { sortArrayOfObjectsByNumber } from '/src/utils/object'
import '/src/static/css/core/popups/print_list_pdf.css'

export default function PrintListPDFExport({ modelName, gridPage }) {
  const storeValues = {
    project: useStore('project')[0],
    subproject: useStore('subproject')[0],
    units: useStore('units')[0],
    progressServiceStatuses: useStore('progress_service_statuses')[0]
  }

  const { fetchColumnsAndTemplate } = usePDFColumnsAndTemplate()

  // eslint-disable-next-line max-params
  const exportPDF = (gridData, gridColumns, pdfConfig, templateTitle) => {
    const listWrapper = document.getElementById('print-pdf-content')

    // these methods will need to change when we upgrade React to 18
    render(<PrintListPDFBody
      gridData={gridData}
      gridColumns={gridColumns}
      storeValues={storeValues}
      modelName={modelName}
    />, listWrapper)

    const fileName = templateTitle
      ? `${modelName} - ${templateTitle} - Page ${gridPage}`
      : `${modelName} - Page ${gridPage}`

    const { forcePageBreak, scale } = pdfConfig
    
    const config = {
      paperSize: 'A4',
      margin: 15,
      fileName,
      scale,
      forcePageBreak: forcePageBreak && '.page-break'
    }

    savePDF(listWrapper, config, () => unmountComponentAtNode(listWrapper))
  }

  useBus(
    BusEvents.PRINT_LIST,
    ({ payload }) => {
      const { model, gridData, pdfConfig } = payload
      if (model.name !== modelName) return

      if (modelName.includes('Scaffolding')) return

      const eavTemplateId = gridData?.[0]?.eav_template_id

      if (!eavTemplateId) return

      fetchColumnsAndTemplate(eavTemplateId, modelName)
        .then(([columnData, templateTitle]) => {
          const orderedColumns = sortArrayOfObjectsByNumber(columnData, 'position')
          exportPDF(gridData, orderedColumns, pdfConfig, templateTitle)
        })
    },
    [modelName, gridPage]
  )

  return (
    <div className='print-list-pdf'>
      <div id='print-pdf-content' data-testid='print-pdf-content' />
    </div>
  )
}

PrintListPDFExport.propTypes = {
  modelName: PropTypes.string,
  gridPage: PropTypes.number.isRequired
}

PrintListPDFExport.defaultProps = {
  modelName: undefined
}

import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from '/src/utils/object'
import { categorizePicturesByColumns } from '/src/utils/pdf_utils'

const BASE_URL = `${window.location.origin}/management`

export default function PrintListPDFPictureColumns({ item, columns, storeValues }) {
  if (!columns?.length) return null

  const { project, subproject } = storeValues

  const subprojectQuery = `&subproject_id=${subproject.id}&project_id=${project.id}`

  const picturesCategorizedByColumn = categorizePicturesByColumns(item, columns)

  if (isEmpty(picturesCategorizedByColumn)) return null

  const filteredColumns = columns.filter((column) => {
    const usedColumns = Object.keys(picturesCategorizedByColumn)
    return usedColumns.includes(column.description)
  })

  return (
    <div className='print-list-pdf__picture-columns'>
      {filteredColumns.map((column) => (
        <div className='print-list-pdf__picture-column' key={column.description}>
          <div className='print-list-pdf__column-title'>
            {column.title}
          </div>
          <div className='print-list-pdf__pictures'>
            {picturesCategorizedByColumn[column.description].map((picture) => {
              const imageSrc = `${BASE_URL + picture.file_path}?${subprojectQuery}`

              return (
                <div
                  key={picture.id}
                  data-testid='print-list-picture'
                  className='print-list-pdf__picture'
                  style={{ backgroundImage: `url(${imageSrc})` }}
                />
              )
            })}
          </div>
        </div>
      ))}
    </div>
  )
}

PrintListPDFPictureColumns.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.arrayOf(PropTypes.shape({
    column_type: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string
    })
  })),
  storeValues: PropTypes.shape({
    project: PropTypes.shape({ id: PropTypes.number }),
    subproject: PropTypes.shape({ id: PropTypes.number }),
  }).isRequired
}

PrintListPDFPictureColumns.defaultProps = {
  item: undefined,
  columns: undefined
}

import React from 'react'
import I18n from '/src/utils/translations.js'
import PropTypes from 'prop-types'

export default function EmptyState({ imageSrc, modelNameLower, message, children }) {
  return (
    <div className="empty-state">
      <div className="empty-state-svg">
        <img className="dpms-logo-collapsed" src={`/static/svg/empty-state-${imageSrc}.svg`} alt="empty-requests" />
      </div>
      <div className="empty-description">
        <div className="empty-title">{I18n.t('empty_state.empty', { model: modelNameLower })}</div>
        <div className="empty-subtitle">{message || I18n.t('empty_state.not_created', { model: modelNameLower })}</div>
      </div>
      {children}
    </div>
  )
}

EmptyState.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  modelNameLower: PropTypes.string.isRequired,
  message: PropTypes.string,
  children: PropTypes.node
}

EmptyState.defaultProps = {
  message: '',
  children: undefined
}

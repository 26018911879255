import React from 'react'
import Layout from '/src/ui/core/layouts/layout'

export default function Estimate({ cookies }) {
  return (
    <Layout cookies={cookies}>
      <p>I can&apos;t really estimate anything, man. Leave me alone...</p>
    </Layout>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { isBlank } from '/src/utils/boolean_refinements'

export default function PrintListPDFMultilineColumns({ item, columns }) {
  if (!columns?.length) return null

  return (
    <div className='print-list-pdf__multiline-columns'>
      {columns.map((column) => (
        <div className='print-list-pdf__multiline-column' key={column.description}>
          <div className='print-list-pdf__column-title'>
            {column.title}
          </div>
          <div className='print-list-pdf__column-value'>
            {isBlank(item[column.description]) ? I18n.t('fields.na'): String(item[column.description])}
          </div>
        </div>
      ))}
    </div>
  )
}

PrintListPDFMultilineColumns.propTypes = {
  item: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.arrayOf(PropTypes.shape({
    column_type: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string
    })
  }))
}

PrintListPDFMultilineColumns.defaultProps = {
  item: undefined,
  columns: undefined
}

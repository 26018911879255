import React from 'react'
import PropTypes from 'prop-types'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import '/src/static/css/domain/inspections/inspected_status_label.css'

export default function InspectedTypeLabel({ label, icon }) {
  return (
    <CustomTooltip maxLength={14}>
      <div className="align-cells-center">
        <div className="inspection label workflow-status registered" role="status">
          <span className="label-icon">{icon}</span>
          {label}
        </div>
      </div>
    </CustomTooltip>
  )
}

InspectedTypeLabel.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
}
